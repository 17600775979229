import logo from "../../assets/logo/horizontal-logo.svg";
import { GITHUB_LINK } from "../../configs/github-link";
import { DOCS_LINK, INTEGRATIONS_LINK } from "../../configs/integrations-link";
import { Link } from "react-router-dom";
import { Socials } from "./Socials";

export default function Footer() {
  const scrollTop = () => {
    window.scrollTo({ top: 0 });
  };
  return (
    <footer className="bg-primary-500 text-white/90 px-5 flex items-center flex-col">
      <div className="w-full max-w-[93rem]">
        <div className="  flex flex-col flex-wrap py-2 md:py-8 md:flex-row md:flex-nowrap md:items-center lg:items-start">
          <div className=" w-full flex flex-col items-center md:items-start space-y-8 text-center md:mx-0 md:text-left">
            <div className="flex-shrink-0">
              <Link to={"/"} onClick={() => scrollTop()}>
                <img className="h-8" src={logo} alt="Equito" />
              </Link>
            </div>
            <div className="hidden md:flex justify-between  flex-row w-full">
              <div className="flex items-baseline space-x-4">
                <a
                  href={DOCS_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Docs
                </a>

                {/* <Link
                  to="/security"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Security
                </Link> */}

                {/*                 <a
                  href={GITHUB_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Developers
                </a> */}

                {/* <a
                  href={INTEGRATIONS_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Integration
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="  flex flex-col flex-wrap py-4 sm:flex-row">
            <div className="bg-dark h-0.5 w-full mb-6 hidden md:block"></div>
            <p className="text-center text-sm text-gray-500 sm:text-left order-1 md:order-none font-Inter">
              © 2023 Equito. All rights reserved.
            </p>
            <span className="mt-2 inline-flex justify-center sm:mt-0  order-0 md:order-none my-6">
              <Socials />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
