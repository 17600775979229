import React, { FC } from "react";

const MissionCard: FC = () => {
  return (
    <>
      <div className="flex justify-center mt-11">
        <div
          className="border-cardBorder border-[1px] p-[5rem] rounded-md text-center w-full max-w-5xl"
          style={{
            boxShadow: "4px 0px 51px rgba(122, 142, 251, 0.5)",
          }}
        >
          <p className="font-oxanium">
            <span>Our </span>
            <span className="text-accent-500">mission </span>
            <span>
              is to lead the digital asset industry forward with research-based
              security innovations that unify web3.
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default MissionCard;
