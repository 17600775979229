import { FC } from "react";
import { EqAnchor } from "../../../shared/EqAnchor";
import { CODE_SNIPPET } from "../../code-snippet";

export const ProvenTech: FC = () => {
  return (
    <>
      <section className="my-[180px] flex flex-wrap justify-evenly">
        <div className="flex-1 lg:pr-4 max-w-[659px]">
          <h2>Proven Tech</h2>
          <p>
            Equito is a multisig protocol that requires consensus of a
            distributed network of independent validators to process
            transactions. Unlike other bridge protocols, Equito will never have
            access to transmitted digital assets before, during or after a
            transaction is executed.
          </p>
          <div className="flex mt-5 justify-center lg:justify-start">
            <EqAnchor
              label="Audits"
              link="https://www.certik.com/projects/equito-finance?utm_source=SkyEmblem&utm_campaign=equito-finance&utm_medium=card"
            />
          </div>
        </div>
        {/* <div className="flex justify-center items-center w-full lg:w-auto mt-11 lg:mt-0 lg:pl-4">
          <iframe
            srcDoc={CODE_SNIPPET}
            title="Equito"
            className="lg:w-[450px] w-full max-w-[450px]"
            height="300px"
          ></iframe>
        </div> */}
      </section>
    </>
  );
};
