import { ReactNode } from "react";
//import { Spinner } from "../spinner/Spinner";

interface SmartTableProps<T> {
  title: string;
  subtitle?: () => ReactNode;
  columns: {
    key: Extract<keyof T | "actions" | "index", string>;
    label: string;
    content: (props: T, index: number, offset?: number) => ReactNode;
  }[];
  items: T[] | "loading";
}

export function SmartTable<T>({
  title,
  subtitle,
  columns,
  items,
}: SmartTableProps<T>) {
  return (
    <>
      <div className="font-Inter flex flex-col lg:py-0 px-2 lg:px-0 border-[1px] rounded-md border-secondary text-dark">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full ">
                <thead className="text-normal-text ">
                  <tr className="border-secondary">
                    <th
                      scope="col"
                      colSpan={3}
                      className="text-xl text-white text-normal-text font-normal text-[18px] lg:text-[24px] px-6 py-6 text-left"
                    >
                      {title}
                    </th>
                  </tr>
                </thead>
                <thead className="">
                  <tr className="border-t-[0.5px] border-t-divider h-[47px] border-secondary">
                    {columns.map((col) => (
                      <th key={col.key} scope="col" className="px-6">
                        <span className="flex text-[15px] text-table-text-color text-start capitalize">
                          {col.label}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {items !== "loading" ? (
                    items.map((item: T, index: number) => (
                      <tr
                        key={index}
                        className="border-t-[0.5px] border-t-divider h-[78px] border-secondary"
                      >
                        {columns.map((col, index) => (
                          <td
                            key={col.key}
                            className="px-6 lg:text-[18px] text-[15px]"
                          >
                            <span>{col.content(item, index)}</span>
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length}>
                        <div className="flex justify-center justify-items-center mb-2">
                          {/*  <Spinner /> */}
                          <strong>SPINNER</strong>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
