import { ReactNode } from "react";

type FilterPropertiesByReturnType<T, PropertyReturnType> = {
  [Property in keyof T as `${T[Property] extends PropertyReturnType
    ? string & Property
    : never}`]: T[Property];
};

type FilteredKeys<T, R> = Extract<
  keyof T,
  Extract<keyof FilterPropertiesByReturnType<T, R>, string>
>;

export class TableCol<T> {
  key: FilteredKeys<T, string> | "actions" | "index";
  label: string;
  content: (item: T, index: number, offset?: number) => ReactNode;
  constructor(
    key: FilteredKeys<T, string> | "actions" | "index",
    label?: string,
    content?: (item: T, index: number, offset?: number) => ReactNode
  ) {
    this.key = key;
    this.label = label || key;
    if (!content) {
      this.content = (item: T, index: number) =>
        key !== "actions" && key !== "index" && item[key]
          ? (item[key] as unknown as string)
          : "---";
    } else {
      this.content = content;
    }
  }
}
