import React from "react";
import { TableCol } from "../../../shared/table/table-col";
import { Audit } from "../models/audit";
import { SmartTable } from "./SmartTable";

const AUDITS: Audit[] = [
  {
    event: "Equito Finance",
    date: "28/11/2022",
    status: "done",
    link: "https://www.certik.com/projects/equito-finance?utm_source=SkyEmblem&utm_campaign=equito-finance&utm_medium=card",
  },
  {
    event: "Equito Finance Solidity",
    date: "28/11/2022",
    status: "done",
    link: "https://www.certik.com/projects/equito-finance?utm_source=SkyEmblem&utm_campaign=equito-finance&utm_medium=card",
  },
];

const AUDITS_TABLE_CONFIG: TableCol<Audit>[] = [
  new TableCol("event", "Event", (item, index: number) => (
    <div className="text-white lg:w-[600px]">
      <a href={item.link} target="_blank" rel="noreferrer">
        {item.event}
      </a>
    </div>
  )),
  new TableCol("date"),
  new TableCol("status", "Status", (item, index: number) => (
    <>
      {item.status === "done" ? (
        <div className="px-2 py-1 text-[12px] bg-[#1D484D] text-[#42B49E] rounded-full w-fit">
          Done
        </div>
      ) : item.status === "cancelled" ? (
        <div className="px-2 py-1 text-[12px] bg-[#452537] text-[#B43D4F] rounded-full w-fit">
          Cancelled
        </div>
      ) : (
        <div className="px-2 py-1 text-[12px] bg-[#7388ff33] text-accent-500 rounded-full w-fit whitespace-nowrap">
          In progress
        </div>
      )}
    </>
  )),
];

export const AuditsTable = () => {
  return (
    <div className="w-full lg:w-auto">
      <SmartTable
        title="Our report"
        items={AUDITS}
        columns={AUDITS_TABLE_CONFIG}
      ></SmartTable>
    </div>
  );
};
