import React, { FC, useEffect, useState } from "react";
import FooterServices from "../../services/footerService";

export const Socials: FC = () => {
  const [footer, setFooter] = useState<any>([]);

  useEffect(() => {
    geFooter();
  }, []);

  const geFooter = async () => {
    let footer = await FooterServices.getFooter();

    footer.data.data.attributes.social.map((item: any) => {
      item["urlPhoto"] =
        item.logo && item.logo.data.attributes.url
          ? "https://cms.equitofinance.com" + item.logo.data.attributes.url
          : "";
      return item;
    });

    setFooter(footer.data.data.attributes.social);
  };
  return (
    <div className="flex">
      {footer.map((item: any) => (
        <a
          key={`itemMenu-${item.link}`}
          href={`${item.link}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            key={`itemMenu-${item.urlPhoto}`}
            className="h-6 w-6 mx-2 cursor-pointer"
            src={item.urlPhoto}
            alt="ms"
          />
        </a>
      ))}
    </div>
  );
};
