import React from "react";
import { Link } from "react-router-dom";
import { EqButton } from "../../../shared/EqButton";
import { EqAnchor } from "../../../shared/EqAnchor";

const DigitalAssets = () => {
  const scrollToSolutions = () => {
    setTimeout(() => {
      let cCSection = document.getElementById("crossChainAssets");
      cCSection?.scrollIntoView();
    }, 100);
  };
  return (
    <section className="min-h-[calc(100vh_-_64px)] flex justify-center items-center">
      <div className="lg:flex-1">
        <h1>Digital Assets Secured</h1>
        <p>
          Equito is on the cutting edge of web3 interoperable security
          solutions.
          <br></br>
          Digital asset security is constantly evolving and Equito Labs pioneers
          emerging technology to build better interoperability solutions.
        </p>
        <div className="flex mt-5 justify-center lg:justify-start">
          <Link to={"/"}>
            <EqButton
              label="Equito solutions"
              onClick={() => scrollToSolutions()}
            />
          </Link>

          <div className="ml-4">
            <EqAnchor
                label="Get Started"
                link="https://bridge.equitofinance.com"
                accent={true}
              /> 
          </div>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-1"></div>
    </section>
  );
};

export default DigitalAssets;
