import { Suspense, useState } from "react";
import { Navbar } from "./components/core/Navbar";
import { SidenavContainer } from "./components/core/SidenavContainer";
import Footer from "./components/core/Footer";
import Loader from "./components/shared/loader/Loader";
import Routes from "./routes/routers";

export const App = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="min-h-full bg-dark-blue bg-cover font-opensansRegular text-white">
      <Navbar
        isSidenavOpen={open}
        toggleMenuCallback={(bool: boolean) => setOpen(bool)}
      />
      <main>
        <div className="flex flex-col top-0 h-full">
          <Suspense fallback={<Loader />}>
            <Routes />
          </Suspense>
        </div>
      </main>
      <SidenavContainer
        isOpen={open}
        setIsOpen={(bool: boolean) => setOpen(bool)}
      ></SidenavContainer>
      <Footer></Footer>
    </div>
  );
};
