import { FC } from "react";
import OPENSOURCE from "../../../../assets/background-image/open-source.svg";
import { DISCORD_LINK } from "../../../../configs/discord-link";
import DISCORD from "../../../../assets/svg/discord.svg";

import { EXPERT_LINK } from "../../../../configs/expert-link";
import { GITHUB_LINK } from "../../../../configs/github-link";
import { EqAnchor } from "../../../shared/EqAnchor";

export const OpenSource: FC = () => {
  return (
    <>
      <section className="w-full flex items-center flex-wrap relative justify-center lg:mt-[150px] lg:mb-[300px] mb-[180px]">
        <div className="flex flex-wrap-reverse max-w-[93rem]">
          <div className="pr-6 lg:flex-1 lg:pr-0">
            <img
              className="lg:absolute lg:left-0 lg:bottom-[-200px]"
              src={OPENSOURCE}
              alt=""
            />
          </div>
          <div className="lg:flex-1 p-6 lg:p-0">
            <h2>Open Source</h2>
            <p>
              Equito believes that community development is greater than the sum
              of its parts. We will always publish our technology out in the
              open for peer review and contributions.
            </p>
            <div className="flex flex-col items-center lg:flex-row mt-5 justify-center lg:justify-start lg:min-w-[559px] max-w-[659px] lg:items-center">
              <div className="my-2 lg:my-0">
                <EqAnchor
                  label={"See for yourself"}
                  link={GITHUB_LINK}
                ></EqAnchor>
              </div>

              <div className="my-2 lg:ml-4 lg:my-0">
                <EqAnchor
                  label={"Speak with an expert"}
                  link={EXPERT_LINK}
                ></EqAnchor>
              </div>

              <div className="my-2 lg:ml-4 lg:my-0">
                <EqAnchor
                  label={"Join our Discord"}
                  link={DISCORD_LINK}
                  icon={DISCORD}
                ></EqAnchor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
