import { FC } from "react";
import { EquitoSdk } from "./components/EquitoSdk";
import { SolutionsCard } from "./components/SolutionsCard";
import { BlockchanInfrastructure } from "./components/BlockchainInfrastructure";
import { CrossChainAssets } from "./components/CrossChainAssets";
import { CrossChainDapps } from "./components/CrossChainDapps";
import { BridgeNetworks } from "./components/BridgeNetworks";
import Team from "./components/Team";
import TeamMobile from "./components/TeamMobile";
import { RoadMap } from "./components/RoadMap";

export const Solution: FC = () => {
  return (
    <>
      {/* bg-solutions */}
      <div className="min-h-full  bg-no-repeat bg-right-top bg-[length:158%] md:bg-[length:70%] xl:bg-auto flex justify-center mt-[-64px]">
        <div className="p-6 w-full lg:max-w-[93rem]">
          <BridgeNetworks></BridgeNetworks>
          {/* <SolutionsCard></SolutionsCard> */}
          {/* <BlockchanInfrastructure /> */}
          {/* <CrossChainAssets /> */}
          {/* <CrossChainDapps /> */}
          {/* <Team />
          <TeamMobile /> */}
          {/* <RoadMap /> */}
          {/* <EquitoSdk></EquitoSdk> */}
        </div>
      </div>
    </>
  );
};
