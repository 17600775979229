import { FC } from "react";
// import ComingSoonButton from "../../../shared/ComingSoonButton";
import { EqButton } from "../../../shared/EqButton";
import { EqAnchor } from "../../../shared/EqAnchor";
import { DOCS_LINK } from "../../../../configs/integrations-link";

export const BridgeNetworks: FC = () => {
  const scrollToSolutions = () => {
    let cCSection = document.getElementById("crossChainAssets");
    cCSection?.scrollIntoView();
  };
  return (
    <>
      <section className="min-h-screen flex justify-center items-center">
        <div className="flex-1">
          <h1><span className="text-accent-500">v.2</span> coming soon</h1> 
          <p className="font-oxanium">
          Equito is a cross-chain messaging protocol for institutions and Web3 ecosystems to interoperate approaching real-time.
            {/* <br></br>
            Learn more about Equito SDK implementation and secure cross-chain
            connections. */}
          </p>
          <div className="flex mt-5 justify-center lg:justify-start">
            <EqAnchor
              label="Learn about Equito"
              link={DOCS_LINK}
              accent={false}
            /> 
            <div className="ml-4">
            <EqAnchor
                label="Launch v.1"
                link="https://bridge.equitofinance.com"
                accent={true}
              /> 
            </div>
          </div>
        </div>
        <div className="flex-1 hidden lg:flex"></div>
      </section>
    </>
  );
};
