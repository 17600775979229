import axios from "axios";

const getFooter = async (limit?: number, skip?: number) => {
  return axios
    .get(
      "https://cms.equitofinance.com/api/footer?populate[social][populate]=*",{ params: { _limit: limit, _start: skip} }
    )
};

const FooterService = {
  getFooter,
};

export default FooterService;
