import { useRoutes, Navigate } from "react-router-dom";
import { Security } from "../components/routes/security/Security";
import { Solution } from "../components/routes/solution/Solution";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Solution />,
    },
    {
      path: "/solution",
      element: <Solution />,
    },
    {
      path: "/security",
      element: <Security />,
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
