import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { GITHUB_LINK } from "../../configs/github-link";
import { DOCS_LINK, INTEGRATIONS_LINK } from "../../configs/integrations-link";

const MobileNavbar: FC<{
  closeSidenav: () => void;
}> = ({ closeSidenav }) => {
  const location = useLocation().pathname;
  return (
    <div className="flex flex-col items-baseline text-[16px] mt-6">
      <a
        href={DOCS_LINK}
        target="_blank"
        rel="noreferrer"
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
      >
        Docs
      </a>

      {/* <Link
        to="/security"
        className={`bg-gray-900 text-white rounded-md px-3 py-4 w-full hover:bg-accent-300 font-medium ${
          location === "/security" ? "bg-accent-300" : ""
        }`}
        onClick={() => closeSidenav()}
      >
        Security
      </Link> */}

      {/*       <a
        href={GITHUB_LINK}
        target="_blank"
        rel="noreferrer"
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-4 w-full hover:bg-accent-300 font-medium"
      >
        Developers
      </a> */}

      {/* <a
        href={INTEGRATIONS_LINK}
        target="_blank"
        rel="noreferrer"
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-4 w-full hover:bg-accent-300 font-medium"
      >
        Integration
      </a> */}
    </div>
  );
};

export default MobileNavbar;
