import { FC } from "react";
import { AuditsTable } from "./AuditsTable";

export const Audits: FC = () => {
  return (
    <>
      <section className="my-[180px] flex justify-around items-center flex-wrap">
        <div className="text-center mb-10">
          <h2>Audits and Deployments</h2>
          <p>
            Equito is and always will be an open-source protocol with a rigorous
            internal and external auditing program. Third-party audit firms are
            also contracted to perform publicly available audits that serve the
            public interest.
          </p>
        </div>
        <AuditsTable></AuditsTable>
      </section>
    </>
  );
};
