import { FC } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/horizontal-logo.svg";
import HAMBURGER from "../../assets/svg/hamburger-menu.svg";
import CLOSE from "../../assets/svg/close-icon.svg";
import { GITHUB_LINK } from "../../configs/github-link";
import { DOCS_LINK, INTEGRATIONS_LINK } from "../../configs/integrations-link";
import { EqAnchor } from "../shared/EqAnchor";

export const Navbar: FC<{
  isSidenavOpen: boolean;
  toggleMenuCallback: (isOpen: boolean) => void;
}> = ({ isSidenavOpen, toggleMenuCallback }) => {
  return (
    <>
      <nav className="w-full relative z-2">
        <div className="mx-auto max-w-[93rem] px-4 sm:px-6">
          <div className="flex h-16 items-center justify-between">
            <div className="flex-shrink-0">
              <Link to={"/"}>
                <img className="h-8" src={logo} alt="Equito" />
              </Link>
            </div>
            <div className="hidden md:flex flex-row justify-end w-full">
              <div className="ml-10 flex items-baseline space-x-4 ">
                {/* <Link
                  to="/solution"
                  className="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium"
                  aria-current="page"
                >
                  Solution
                </Link> */}

                <a
                  href={DOCS_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Docs
                </a>

                {/*                 <a
                  href={GITHUB_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Developers
                </a> */}

                {/* <a
                  href={INTEGRATIONS_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                >
                  Integration
                </a> */}
              </div>
            </div>

            <div className="flex">
              <div className=" flex flex-row justify-end md:justify-start items-center md:ml-6 w-[200px]">

              <EqAnchor
                label="Launch v.1"
                link="https://bridge.equitofinance.com"
                accent={true}
              /> 
              
              </div>
              <div className="ml-2 flex md:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => toggleMenuCallback(!isSidenavOpen)}
                >
                  <span className="sr-only">Open main menu</span>
                  <img
                    src={!isSidenavOpen ? HAMBURGER : CLOSE}
                    alt={
                      !isSidenavOpen
                        ? "open sidenav menu"
                        : "close sidenav menu"
                    }
                  ></img>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
