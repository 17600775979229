import { FC } from "react";

export type EqButtonProps = {
  label: string;
  onClick: () => void;
  accent?: boolean;
  icon?: string;
};

export const EqButton: FC<EqButtonProps> = ({
  label,
  accent,
  icon,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className={`py-2 px-4 ${
        accent
          ? "bg-accent-500 hover:bg-accent-700 border-[1px] border-accent-500"
          : "bg-transparent hover:bg-primary-900 border-[1px] border-white"
      } transition ease-in duration-200 text-center text-base shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg w-fit `}
    >
      {icon ? (
        <img className="h-5 w-5 inline-block mr-3" src={icon} alt="" />
      ) : (
        <span></span>
      )}
      <span>{label}</span>
    </button>
  );
};
