import { FC } from "react";
import { EqButtonProps } from "./EqButton";

type EqAnchorProps = Omit<EqButtonProps, "onClick"> & { link: string };

export const EqAnchor: FC<EqAnchorProps> = ({ label, accent, icon, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`py-2 px-4 block ${
        accent
          ? "bg-accent-500 hover:bg-accent-700 border-[1px] border-accent-500"
          : "bg-transparent hover:bg-primary-900 border-[1px] border-white"
      } transition ease-in duration-200 text-center text-base shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg w-fit `}
    >
      {icon ? (
        <img className="h-5 w-5 inline-block mr-3" src={icon} alt="" />
      ) : (
        <span></span>
      )}
      <span>{label}</span>
    </a>
  );
};
