import { FC } from "react";
import { Audits } from "./components/Audits";
import DigitalAssets from "./components/DigitalAssets";
import MissionCard from "./components/MissionCard";
import { OpenSource } from "./components/OpenSource";
import { ProvenTech } from "./components/ProvenTech";

export const Security: FC = () => {
  return (
    <>
      <div className="min-h-full flex flex-col items-center w-full bg-fingerprint-mobile bg-[center_top_2rem] md:bg-fingerprint md:bg-[right_5rem_top_4rem] bg-no-repeat">
        <div className="p-6 w-full lg:max-w-[93rem]">
          <DigitalAssets />
          <MissionCard />
          <Audits />
          <ProvenTech />
        </div>
        <OpenSource />
      </div>
    </>
  );
};
